// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("jquery");
require("webpack-jquery-ui");
require("@nathanvda/cocoon");
require("@popperjs/core");
require('../custom/common');
require('../custom/home');
require("trix");
require("@rails/actiontext");

import "bootstrap";

import jquery from 'jquery';
window.$ = window.jquery = jquery;

const images = require.context('../images', true)

import 'owl.carousel';

import { Tooltip, Popover } from "bootstrap";

$(document).on("turbolinks:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
  })
});
