$(document).ready(function() {
  if($('#editor').length > 0)
    CKEDITOR.replace( 'editor',
      {
        toolbar :
        [
          { name: 'basicstyles', items : ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'] },
          { name: 'paragraph', items : ['BulletedList', 'NumberedList'] },
          { name: 'document', items : ['NewPage', 'Preview'] },
          { name: 'clipboard', items : ['Cut', 'Copy','Paste','PasteText','PasteFromWord','-','Undo','Redo'] },
          { name: 'editing', items : ['Find','Replace','-','SelectAll','-','Scayt'] },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
          { name: 'insert', items: ['Image'] },
          { name: 'styles', items: ['Format', 'FontSize'] },
          { name: 'colors', items: ['TextColor', 'BGColor'] }
        ]
      });
    });

  function toggleMe(truncated, full, more, less) {
    let truncated_text = document.getElementById(truncated);
    let full_text = document.getElementById(full);
    let more_link = document.getElementById(more);
    console.log('line 24');
    let less_link = document.getElementById(less);
    if(!truncated_text || !full_text)
      return true;

    if(truncated_text.style.display == "none"){
      truncated_text.style.display ="block"
      full_text.style.display = "none"
      more_link.style.display = "none"
      less_link.style.display = "block"
    }
    else
    {
      truncated_text.style.display ="none"
      full_text.style.display = "block"
      more_link.style.display = "block"
      less_link.style.display = "none"
    }
    return true;
  };

  $(document).on('turbolinks:load', function () {
    $("#re-order").sortable();
  });

  // $(document).on("click", "#more", function() {
  //   $('#truncated').hide();
  //   $('#full-text').show();
  // });

  // $(document).on("click", "#less", function() {
  //   $('#truncated').show();
  //   $('#full-text').hide();
  // });

  $(document).on("click", ".update-order", function() {
    var ids = $.map($('#re-order').children('tr'), function(v) { return v.id });
    var url = $(this).data('url');
    $.ajax({
      url: url,
      method: 'PATCH',
      data: {
        ids: ids
      }
    });
  });

  $(document).on("click", '#sidebarToggle', function() {
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    }
    $('#sidebar').addClass('active');
    $('.overlay').toggleClass('active');
    $('.collapse.in').toggleClass('in');
    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });
