$(document).ready(function(){
  $(".search-field").autocomplete({
    source: function(request, response){
      $.ajax({
        url: "/home/suggestions",
        data: { q: $(".search-field").val(), search_terms: { search_topic: $("#search_terms_search_topic").val(), search_sub_topic: $("#search_terms_search_sub_topic").val() } },
        dataType: "json",
        success: function(data){
          response($.map(data, function(item) { return { value: item.title }}));
        }
      });
    }
  });

  $('#search_terms_search_topic, #search_terms_search_sub_topic').change(function() {
    var topicId = $('#search_terms_search_topic').val();
    var subtopicId = $('#search_terms_search_sub_topic').val();
    selectedDropdownId = this.id

    $.ajax({
      url: '/home/get_related_data',
      data: { search_terms: { search_topic: topicId, search_sub_topic: subtopicId }},
      dataType: 'json',
      success: function(response) {
        const isSubTopicChange = selectedDropdownId === 'search_terms_search_sub_topic';
        const targetSelect = isSubTopicChange ? $('#search_terms_search_topic') : $('#search_terms_search_sub_topic');
        const items = isSubTopicChange ? response.topics : response.sub_topics;
        const placeholder = isSubTopicChange ? 'All Quranic Subjects' : 'All Topics';

        targetSelect.empty().append($('<option>', { value: '', text: placeholder }));

        $.each(items, function(index, item) {
          targetSelect.append($('<option>', {
            value: item.id,
            text: item.name
          }));
        });

        targetSelect.val(isSubTopicChange ? topicId : subtopicId);
      }
    });
  });
});
